<template>
    <div id="root" class="h-full flex flex-col" @mousedown="clickHandler">
        <NuxtLoadingIndicator />
        <HeaderDefault />
        <HeaderMobile />
        <main id="main" class="grow">
            <slot />
        </main>
        <FooterDefault />
    </div>
</template>
<script setup lang="ts">
const clickHandler = useClickHandler();
</script>
